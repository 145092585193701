<template>
    <div class="box">
        当前用户无用户app权限，请开通后再试！
    </div>
</template>
<script lang="ts">


</script>
<style scoped>
.box {
    width: 100%;
    display: flex;
    height: 400px;
    justify-content: center;
    margin-top: 200px;
    font-size: 50px;
}
</style>